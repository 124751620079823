import React, { FunctionComponent } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import VerticalSpacer from '@sport1/news-styleguide/VerticalSpacer'
import ListTeaserSkeleton from '@sport1/news-styleguide/ListTeaserSkeleton'

type Props = {
    testID?: string
}

const TeaserCardRowSkeleton: FunctionComponent<Props> = ({ testID }) => (
    <NonFlexingContainer>
        <VerticalSpacer backgroundColor="pearl" space="standard" />
        <ListTeaserSkeleton testID={testID} />
    </NonFlexingContainer>
)

export default TeaserCardRowSkeleton
