import React, { FC, memo, Fragment } from 'react'
import { spacings } from '@sport1/news-styleguide/spacings'
import { ItemCardProps } from '..'
import { TeaserSizeTypes } from '@/types/teaser'
import { defaultDisplaySizes } from '@/hooks/useBreakpointDisplaySize'

const DynamicTeaserDisplay: FC<ItemCardProps> = ({
    title,
    hasRightColumn,
    teaser,
    getTeaserComponent,
    displaySizes = defaultDisplaySizes,
}) => {
    if (teaser.length > 1 && teaser.length < 4) return null
    const { isMobile, isTablet, isDesktop } = displaySizes

    return (
        <div data-testid="dynamic-teaser-display">
            {isDesktop && (
                <>
                    <div
                        style={{ marginBottom: spacings['spacing-6'] }}
                        data-testid="desktop-teasers-wrapper"
                    >
                        {getTeaserComponent({
                            index: 0,
                            type: hasRightColumn
                                ? TeaserSizeTypes.BIG
                                : TeaserSizeTypes.HORIZONTAL_BIG,
                            isCardTeaser: !isMobile,
                            includeAdsForSizes: {
                                isDesktop: true,
                                isTablet: true,
                                isMobile: false,
                            },
                        })}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                            gap: spacings['spacing-6'],
                        }}
                    >
                        {Array.from({ length: teaser.length - 1 }, (_, index) => {
                            return (
                                <div
                                    data-testid="desktop-teaser-element"
                                    key={`${teaser[index].componentKey}_${title}_desktop_wrapper`}
                                    style={{
                                        width: `calc((100% / ${hasRightColumn ? 2 : 3}) - ${
                                            hasRightColumn
                                                ? spacings['spacing-3']
                                                : spacings['spacing-4']
                                        })`,
                                    }}
                                >
                                    {getTeaserComponent({
                                        index: index + 1,
                                        type: TeaserSizeTypes.MID,
                                        isCardTeaser: isDesktop,
                                        includeAdsForSizes: {
                                            isDesktop: true,
                                            isTablet: false,
                                            isMobile: false,
                                        },
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </>
            )}
            {isTablet && (
                <>
                    <div
                        style={{
                            display: 'flex',
                            gap: spacings['spacing-6'],
                            flexDirection: 'column',
                            maxWidth: '100%',
                            flexGrow: 1,
                        }}
                        data-testid="tablet-teasers-wrapper"
                    >
                        <div>
                            {getTeaserComponent({
                                index: 0,
                                type: TeaserSizeTypes.BIG,
                                isCardTeaser: isTablet,
                                includeAdsForSizes: {
                                    isDesktop: false,
                                    isTablet: true,
                                    isMobile: false,
                                },
                            })}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                gap: spacings['spacing-6'],
                                flexWrap: 'wrap',
                            }}
                        >
                            {Array.from({ length: teaser.length - 1 }, (_, index) => (
                                <div
                                    key={`${teaser[index].componentKey}_${title}_tablet_wrapper`}
                                    style={{
                                        width: `calc((100% / 2) - ${spacings['spacing-3']})`,
                                    }}
                                >
                                    {getTeaserComponent({
                                        index: index + 1,
                                        type: TeaserSizeTypes.MID,
                                        isCardTeaser: isTablet,
                                        includeAdsForSizes: {
                                            isDesktop: false,
                                            isTablet: true,
                                            isMobile: false,
                                        },
                                    })}
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
            {isMobile && (
                <>
                    <div
                        style={{
                            display: 'flex',
                            gap: spacings['spacing-6'],
                            flexDirection: 'column',
                            maxWidth: '100%',
                            flexGrow: 1,
                        }}
                        data-testid="mobile-teasers-wrapper"
                    >
                        <div>
                            {getTeaserComponent({
                                index: 0,
                                type: TeaserSizeTypes.BIG,
                                isCardTeaser: isMobile,
                                includeAdsForSizes: {
                                    isDesktop: false,
                                    isTablet: false,
                                    isMobile: true,
                                },
                            })}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: spacings['spacing-6'],
                            }}
                        >
                            {Array.from({ length: teaser.length - 1 }, (_, index) => (
                                <div key={`${teaser[index].componentKey}_mobile_wrapper`}>
                                    {getTeaserComponent({
                                        index: index + 1,
                                        type: TeaserSizeTypes.LIST,
                                        isCardTeaser: isMobile,
                                        includeAdsForSizes: {
                                            isDesktop: false,
                                            isTablet: false,
                                            isMobile: true,
                                        },
                                    })}
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default memo(DynamicTeaserDisplay)
