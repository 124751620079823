import React, { FunctionComponent, ReactElement, memo } from 'react'
import BigTeaserSkeleton from '@sport1/news-styleguide/BigTeaserSkeleton'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import VerticalSpacer from '@sport1/news-styleguide/VerticalSpacer'

const TopVideoSkeleton: FunctionComponent = (): ReactElement => {
    const testId = 'top-video-skeleton'
    return (
        <NonFlexingContainer backgroundColor="pearl">
            <BigTeaserSkeleton isTopStory testID={`${testId}-big`} />
            <VerticalSpacer
                space="standard"
                backgroundColor="pearl"
                testID={`${testId}-vertical-spacer`}
            />
        </NonFlexingContainer>
    )
}

export default memo(TopVideoSkeleton)
