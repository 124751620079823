import React, { FunctionComponent, ReactElement } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import BigTeaserSkeleton from '@sport1/news-styleguide/BigTeaserSkeleton'
import VerticalSpacer from '@sport1/news-styleguide/VerticalSpacer'
import HorizontalScrollView from '@sport1/news-styleguide/HorizontalScrollView'
import MidTeaserSkeleton from '@sport1/news-styleguide/MidTeaserSkeleton'

const itemsArray = [<div key="1" />, <div key="2" />, <div key="3" />]

type Props = {
    testID?: string
}

export const BundesligaHubRowSkeleton: FunctionComponent<Props> = ({ testID }): ReactElement => (
    <NonFlexingContainer>
        <VerticalSpacer space="standard" backgroundColor="onyx" testID={testID} />
        <HorizontalScrollView<ReactElement>
            backgroundColor="onyx"
            spaceBetween="standard"
            spaceX="standard"
            items={itemsArray}
            keyExtractor={(_: ReactElement, index: number): string =>
                `mid-teaser-skeleton_${index}`
            }
            renderItem={(): ReactElement => <MidTeaserSkeleton />}
        />
    </NonFlexingContainer>
)

const BundesligaHubSkeleton: FunctionComponent = (): React.ReactElement => {
    const testId = 'bundesliga-hub-skeleton'
    return (
        <NonFlexingContainer
            backgroundColor="onyx"
            testID="bundesliga-hub-skeleton"
            marginBottom="spacing-9"
            height={['1570px', '1570px', '1365px', '1145px']}
        >
            <NonFlexingContainer paddingX="spacing-2">
                <VerticalSpacer
                    space="standard"
                    backgroundColor="onyx"
                    testID={`${testId}-vertical-spacer-0`}
                />
                <NonFlexingContainer centerVertical>
                    <BigTeaserSkeleton testID={`${testId}-big`} height="504px" />
                </NonFlexingContainer>
                <BundesligaHubRowSkeleton testID="bundesliga-hub-skeleton-row-vertical-spacer-0" />
                <BundesligaHubRowSkeleton testID="bundesliga-hub-skeleton-row-vertical-spacer-1" />
            </NonFlexingContainer>
            <VerticalSpacer
                space="standard"
                backgroundColor="onyx"
                testID={`${testId}-vertical-spacer-1`}
            />
        </NonFlexingContainer>
    )
}

export default BundesligaHubSkeleton
