import React, { FunctionComponent, ReactElement, memo } from 'react'
import BigTeaserSkeleton from '@sport1/news-styleguide/BigTeaserSkeleton'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import TeaserCardRowSkeleton from '@/components/Skeletons/TeaserCardRowSkeleton'

const TopTeaserCardSkeleton: FunctionComponent = (): ReactElement => {
    const testId = 'top-teaser-card-skeleton'
    return (
        <NonFlexingContainer spaceBottom="standard" backgroundColor="pearl">
            <BigTeaserSkeleton isTopStory testID={`${testId}-big`} />
            <NonFlexingContainer backgroundColor="pearl" spaceBottom="small" spaceX="standard">
                <TeaserCardRowSkeleton testID={`${testId}-list-teaser-skeleton-0`} />
                <TeaserCardRowSkeleton testID={`${testId}-list-teaser-skeleton-1`} />
            </NonFlexingContainer>
        </NonFlexingContainer>
    )
}

export default memo(TopTeaserCardSkeleton)
