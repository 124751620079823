import React, { FC, memo, ReactElement } from 'react'
import { ComponentType, LayoutComponentProps } from '@sport1/types/web'
import { SizeTypes } from '@/types/size'
import { useAdPlacementContext } from '@/context/AdPlacementContext/AdPlacementProvider'
import { getSizeType } from '@/helpers/pageHelper'

type Props = {
    components: LayoutComponentProps[]
    renderAdsOnDevice: Array<SizeTypes | undefined>
    renderElement: ({
        component,
        index,
        isLast,
    }: {
        component: LayoutComponentProps
        index?: number
        isLast?: boolean
    }) => ReactElement | null
}

const RightColumn: FC<Props> = ({ components, renderAdsOnDevice, renderElement }) => {
    const { enrichAdsWithId } = useAdPlacementContext()
    const [enrichedComponents, setEnrichedComponents] = React.useState<LayoutComponentProps[]>([])
    React.useEffect(() => {
        if (enrichedComponents.length === 0) {
            let renderedComponents
            if (renderAdsOnDevice.includes(getSizeType())) {
                enrichAdsWithId(components)
                renderedComponents = components
            } else {
                renderedComponents = components.filter(
                    component => component.type !== ComponentType.AD
                )
            }
            setEnrichedComponents(renderedComponents)
        }
    }, [components, enrichAdsWithId, enrichedComponents, renderAdsOnDevice])

    return (
        <>
            {enrichedComponents.map((component, index) => {
                return renderElement({ component, index, isLast: index === components.length - 1 })
            })}
        </>
    )
}

export default memo(RightColumn)
