import React, { FunctionComponent, ReactElement, memo } from 'react'
import BigTeaserSkeleton from '@sport1/news-styleguide/BigTeaserSkeleton'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import TeaserCardRowSkeleton from '@/components/Skeletons/TeaserCardRowSkeleton'

const TeaserCardSkeleton: FunctionComponent = (): ReactElement => {
    const testId = 'teaser-card-skeleton'
    return (
        <NonFlexingContainer spaceBottom="standard">
            <NonFlexingContainer
                backgroundColor="pearl"
                spaceBottom="small"
                spaceX="standard"
                spaceTop="standard"
            >
                <BigTeaserSkeleton />
                <TeaserCardRowSkeleton testID={`${testId}-list-teaser-skeleton-0`} />
                <TeaserCardRowSkeleton testID={`${testId}-list-teaser-skeleton-1`} />
                <TeaserCardRowSkeleton testID={`${testId}-list-teaser-skeleton-2`} />
                <TeaserCardRowSkeleton testID={`${testId}-list-teaser-skeleton-3`} />
            </NonFlexingContainer>
        </NonFlexingContainer>
    )
}

export default memo(TeaserCardSkeleton)
