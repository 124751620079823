import React, { FC, memo } from 'react'
import { StreamTeaserProps } from '@sport1/types/web'
import LivestreamPlayer from '@/components/Player/TV'
import { LabelTypesMap } from '@/components/Teaser'

type Props = {
    componentData: StreamTeaserProps
    isCardTeaser?: boolean
    shouldAddMarginBottom?: boolean
}

const StreamTeaser: FC<Props> = ({
    componentData,
    isCardTeaser = false,
    shouldAddMarginBottom,
}) => {
    const {
        id,
        title,
        imageUrl,
        meta,
        label,
        streamingUrl,
        urlType = 'LIVE_STREAM',
        componentKey,
    } = componentData
    const isLivestream = urlType === 'LIVE_STREAM'
    return (
        <LivestreamPlayer
            key={`${title}-${componentKey}`}
            videoId={id}
            label={LabelTypesMap.get(label) === 'breaking' ? 'BREAKING' : 'LIVE'}
            title={title}
            imageUrl={imageUrl || ''}
            videoUrl={streamingUrl?.replace(':authtoken', 'sport2').concat('.m3u8')}
            trackingData={meta?.tracking}
            type={isLivestream ? 'liveStream' : 'eventStream'}
            isCardTeaser={isCardTeaser}
            videoAds={meta?.ad}
            shouldAddMarginBottom={shouldAddMarginBottom}
        />
    )
}

export default memo(StreamTeaser)
