import React, { FC, Fragment, memo } from 'react'
import { spacings } from '@sport1/news-styleguide/spacings'
import { ItemCardProps } from '..'
import { TeaserSizeTypes } from '@/types/teaser'
import { defaultDisplaySizes } from '@/hooks/useBreakpointDisplaySize'

const ThreeItemCardContent: FC<ItemCardProps> = ({
    title,
    hasRightColumn,
    teaser,
    getTeaserComponent,
    displaySizes = defaultDisplaySizes,
}) => {
    if (teaser.length < 3) return null
    const { isMobile, isTablet, isDesktop } = displaySizes

    return (
        <div data-testid="three-item-card-content">
            {isDesktop && (
                <>
                    <div
                        style={{
                            display: 'flex',
                            gap: spacings['spacing-6'],
                            flexDirection: hasRightColumn ? 'column' : 'row',
                            width: '100%',
                        }}
                        data-testid="desktop-teasers-wrapper"
                    >
                        {hasRightColumn && (
                            <div data-testid="first-desktop-teaser">
                                {getTeaserComponent({
                                    index: 0,
                                    type: hasRightColumn
                                        ? TeaserSizeTypes.BIG
                                        : TeaserSizeTypes.MID,
                                    isCardTeaser: !isMobile,
                                    includeAdsForSizes: {
                                        isDesktop: true,
                                        isTablet: true,
                                        isMobile: false,
                                    },
                                })}
                            </div>
                        )}
                        <div style={{ display: 'flex', gap: spacings['spacing-6'] }}>
                            {Array.from({ length: hasRightColumn ? 2 : 3 }, (_, index) => (
                                <div
                                    data-testid="desktop-teasers"
                                    key={`${teaser[index].componentKey}_${title}_desktop_wrapper`}
                                    style={{ flexGrow: 1 }}
                                >
                                    {getTeaserComponent({
                                        index: hasRightColumn ? index + 1 : index,
                                        type: TeaserSizeTypes.MID,
                                        isCardTeaser: isDesktop,
                                        includeAdsForSizes: {
                                            isDesktop: true,
                                            isTablet: false,
                                            isMobile: false,
                                        },
                                    })}
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
            {isTablet && (
                <>
                    <div
                        style={{
                            display: 'flex',
                            gap: spacings['spacing-6'],
                            flexDirection: 'column',
                            maxWidth: '100%',
                            flexGrow: 1,
                        }}
                        data-testid="tablet-teasers-wrapper"
                    >
                        <div>
                            {getTeaserComponent({
                                index: 0,
                                type: TeaserSizeTypes.BIG,
                                isCardTeaser: isTablet,
                                includeAdsForSizes: {
                                    isDesktop: false,
                                    isTablet: true,
                                    isMobile: false,
                                },
                            })}
                        </div>
                        <div style={{ display: 'flex', gap: spacings['spacing-6'] }}>
                            {Array.from({ length: 2 }, (_, index) => (
                                <div key={`${teaser[index].componentKey}_${title}_tablet_wrapper`}>
                                    {getTeaserComponent({
                                        index: index + 1,
                                        type: TeaserSizeTypes.MID,
                                        isCardTeaser: isTablet,
                                        includeAdsForSizes: {
                                            isDesktop: false,
                                            isTablet: true,
                                            isMobile: false,
                                        },
                                    })}
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
            {isMobile && (
                <>
                    <div
                        style={{
                            display: 'flex',
                            gap: spacings['spacing-6'],
                            flexDirection: 'column',
                            maxWidth: '100%',
                            flexGrow: 1,
                        }}
                        data-testid="mobile-teasers-wrapper"
                    >
                        <div>
                            {getTeaserComponent({
                                index: 0,
                                type: TeaserSizeTypes.BIG,
                                isCardTeaser: isTablet,
                                includeAdsForSizes: {
                                    isDesktop: false,
                                    isTablet: false,
                                    isMobile: true,
                                },
                            })}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: spacings['spacing-6'],
                            }}
                        >
                            {Array.from({ length: 2 }, (_, index) => (
                                <div key={`${teaser[index].componentKey}_${title}_mobile_wrapper`}>
                                    {getTeaserComponent({
                                        index: index + 1,
                                        type: TeaserSizeTypes.LIST,
                                        isCardTeaser: isTablet,
                                        includeAdsForSizes: {
                                            isDesktop: false,
                                            isTablet: false,
                                            isMobile: true,
                                        },
                                    })}
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default memo(ThreeItemCardContent)
