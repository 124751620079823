import SmallButton from '@sport1/news-styleguide/SmallButton'
import { TagNavigationItemProps } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import FlexingContainer from '@sport1/news-styleguide/FlexingContainer'
import { FC } from 'react'
import { SystemSpacingsProps } from '@sport1/news-styleguide/Types'
import nofollowExternal from '@/utils/url/nofollowExternal'
import { onCTAPress } from '@/helpers/pageReferringWidgetHelper'

interface Props extends SystemSpacingsProps {
    testID?: string
    ctaButtonUrl?: TagNavigationItemProps | undefined
    buttonText: string
}

const TeamPageCTAButton: FC<Props> = ({ testID, ctaButtonUrl, buttonText, ...rest }) => {
    return (
        <FlexingContainer
            testID={`${testID}-button-container`}
            center
            horizontal
            paddingTop="spacing-10"
            {...rest}
        >
            <NonFlexingContainer
                testID={`${testID}-button-wrapper`}
                marginX={['spacing-none', 'spacing-none', 'auto']}
                width={['100%', '100%', 320]}
            >
                <SmallButton
                    testID={`${testID}-button`}
                    backgroundColor="moon-2"
                    expand
                    href={ctaButtonUrl?.href ?? undefined}
                    onPress={onCTAPress} // triggers page_referring_widget
                    webRel={nofollowExternal}
                >
                    {buttonText}
                </SmallButton>
            </NonFlexingContainer>
        </FlexingContainer>
    )
}

export default TeamPageCTAButton
