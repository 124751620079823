import React, { FunctionComponent, ReactElement, memo } from 'react'
import MidTeaserSkeleton from '@sport1/news-styleguide/MidTeaserSkeleton'
import HorizontalScrollView from '@sport1/news-styleguide/HorizontalScrollView'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import VerticalSpacer from '@sport1/news-styleguide/VerticalSpacer'

const itemArray = [<div key="1" />, <div key="2" />]

const HorizontalCardSkeleton: FunctionComponent = (): ReactElement => {
    return (
        <NonFlexingContainer backgroundColor="pearl">
            <VerticalSpacer space="standard" backgroundColor="pearl" />
            <HorizontalScrollView<ReactElement>
                backgroundColor="pearl"
                spaceBetween="standard"
                spaceX="standard"
                items={itemArray}
                keyExtractor={(_: unknown, index: number): string => `mid-teaser-skeleton_${index}`}
                renderItem={(): ReactElement => <MidTeaserSkeleton />}
            />
            <VerticalSpacer space="standard" backgroundColor="pearl" />
        </NonFlexingContainer>
    )
}

export default memo(HorizontalCardSkeleton)
