import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { ArticleBetterCollectiveProps } from '@sport1/types/web'
import { spacings } from '@sport1/news-styleguide/spacings'
import showBetterCollective from '@/components/BetterCollective/showBetterCollective'
import { useConsentManager } from '@/utils/consent/ConsentInfoProvider'
import { useMobile } from '@/hooks/useMobile'

const betterCollectiveCmpId = '62fe8a9e0f412d061b673ba4'

const BetterCollective: FC<ArticleBetterCollectiveProps> = ({ enabled, activeFolder }) => {
    const ref = useRef<HTMLDivElement>(null)
    const [isAllowed, setIsAllowed] = useState(false)
    const { getConsentById } = useConsentManager()
    const { isMobile } = useMobile()

    const load = useCallback(() => {
        /* istanbul ignore next */
        if (!ref.current) {
            return
        }

        if (isAllowed && !window.betsenseScript) {
            const script = document.createElement('script')
            script.id = 'betsenseScript'
            script.async = true
            script.defer = true
            script.src = 'https://dtokw98w8oklz.cloudfront.net/build.js'
            ref.current.append(script)
        }

        if (isAllowed) {
            if (isMobile) {
                ref.current.style.marginBottom = spacings['spacing-9']
            }
            ref.current.style.display = 'block'
        } else {
            ref.current.style.display = 'none'
        }
    }, [isAllowed, isMobile])

    useEffect(() => {
        const allowed =
            showBetterCollective({ enabled, activeFolder }) &&
            getConsentById('custom', betterCollectiveCmpId)
        setIsAllowed(allowed)
        load()
        window.addEventListener('resize', load)
    }, [getConsentById, load, setIsAllowed, activeFolder, enabled])

    if (!isAllowed) return null

    return (
        <div
            ref={ref}
            className="text-center"
            style={{ display: 'none', paddingInline: '12px' }}
            data-testid="better-collective"
        >
            <span
                className="s1-placement-label"
                style={{
                    paddingBottom: spacings['spacing-4'],
                    height: 13,
                    fontSize: 10,
                    color: 'grey',
                }}
            >
                Anzeige
            </span>
            <div id="betsense" className="bc-placeholder" style={{ height: '320px' }}></div>
        </div>
    )
}

export default BetterCollective
