import React, { FC } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import SkeletonImage from '@sport1/news-styleguide/SkeletonImage'

const EventTeaserCardSkeleton: FC = () => {
    const testId = 'event-teaser-card-skeleton'
    return (
        <NonFlexingContainer testID={testId} backgroundColor="pearl" marginBottom="spacing-9">
            <SkeletonImage height="300px" />
        </NonFlexingContainer>
    )
}

export default EventTeaserCardSkeleton
