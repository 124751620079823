import { ArticleBetterCollectiveProps } from '@sport1/types/web'
import { isMobileView } from '@/helpers/pageHelper'

type showBetterCollectiveProps = Pick<ArticleBetterCollectiveProps, 'enabled' | 'activeFolder'>

const blackList: Record<string, boolean | undefined> = {
    poker: true,
    casino: true,
    unternehmen: true,
}

const showBetterCollective = ({ enabled, activeFolder }: showBetterCollectiveProps): boolean => {
    if (typeof window !== 'undefined') {
        const currentPathName = location.pathname.split('/')[2]
        const isMobile = isMobileView()

        if ((isMobile && enabled === 'desktop') || (!isMobile && enabled === 'mobile')) return false
        /** The activeFolder is used for a desktop Banner that should only be shown on the Folder 'sportwetten' this is set in
         *  ./src/pages/api/cms/content/urlalias/[...slug].ts */
        if (activeFolder && currentPathName !== activeFolder) return false
        return !blackList[currentPathName]
    }

    return false
}

export default showBetterCollective
